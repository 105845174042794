import React, { useState, useEffect } from "react";
import ApiService from "../../ApiService";
import MessageTestDetail from "./MessageTestDetail";
import AppointmentRequest from "./AppointmentRequest";
import MessageReplyChain from "./MessageReplyChain"

const MessageReply = (props) => {
    const [message, setMessage] = useState(null);
    const [component, setComponent] = useState(null);
    const messageUUID = props.messageUUID;

    useEffect(() => {
        if (messageUUID) {
            async function getMessage() {
                try {
                    const getMessage = await ApiService.getMessage(messageUUID);
                    setMessage(getMessage.data);
                } catch(ex) {
                    console.error(ex);
                }
            }
            getMessage();
        }
    }, [messageUUID]);

    useEffect(() => {
        if (message) {
            if (message.messageType === "TEST_RESULT") {
                setComponent(<MessageTestDetail message={message} messageUUID={messageUUID} />);
            } else if (message.messageType === "APPOINTMENT_REQUEST") {
                setComponent(<AppointmentRequest message={message} messageUUID={messageUUID} />);
            } else if (message.messageType === "MESSAGE") {
                setComponent(<MessageReplyChain message={message} messageUUID={messageUUID} />);
            } else {
                setComponent(<MessageReplyChain message={message} messageUUID={messageUUID} />)
            }
        }
    }, [message, messageUUID]);

    if (!component) {
        return (<div className="msg_container"></div>)
    }
    else {
        return (
            <div className="msg_container">
                {component}
            </div>
        )
    }
}

export default MessageReply;
