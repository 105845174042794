import React, { useState } from 'react'
import moment from 'moment'
import TextTruncate from 'react-text-truncate'
import Filedownload from 'js-file-download'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import MessageReply from './MessageReply'
import DOMPurify from 'dompurify'
import ApiService from '../../ApiService'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const MessageReplyChain = props => {
  const message = props.message
  const parentUUID = props.message.messageParentUUID
  const [snackOpen, setSnackOpen] = useState(false)
  const [snackSeverity, setSnackSeverity] = useState('')
  const [snackText, setSnackText] = useState('')

  const handleSnackOpen = (text, severity) => {
    setSnackSeverity(severity)
    setSnackText(text)
    setSnackOpen(true)
  }

  const handleSnackClose = () => {
    setSnackOpen(false)
  }

  const download = async fileName => {
    if (!fileName) {
      return
    }
    await ApiService.downloadFile('MESSAGE_ATTACHMENTS', fileName)
      .then(resp => {
        Filedownload(resp.data, fileName.substring(fileName.indexOf('_') + 1))
      })
      .catch(async error => {
        const data = error?.response?.data
        if (error?.request?.responseType === 'blob' && data instanceof Blob && data?.type && data.type.toLowerCase().indexOf('json') != -1) {
          const errorString = JSON.parse(await data?.text())
          handleSnackOpen(errorString?.reason, 'error')
        }
      })
  }

  if (!message) {
    return (
      <div className="open_message load_message">
        {' '}
        <i className="fas fa-spinner fa-pulse"></i>
      </div>
    )
  } else {
    return (
      <div className="open_message">
        <div className="message_header">
          <div className="from_header_el header-from">{message.from}</div>
          <div className="header-subject">
            <TextTruncate line={1} element="span" truncateText="…" text={message.subject} />
          </div>
          <div className="header-date header-date-reply chain-reply-date">{moment(message.createdDateTime).format('ddd MMM D, YYYY LT')}</div>
        </div>

        <div className="message-margin">
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.content) }} className="message_text"></div>
          {message?.attachmentUrls?.length > 0 ? (
            <div>
              {message.attachmentUrls.map(item => (
                <div className="attachment-files attachment-files-message-text" onClick={() => download(item)}>
                  <div className="file-name-text">{item?.substring(item?.indexOf('_') + 1)}</div>
                </div>
              ))}
            </div>
          ) : null}

          <div id="reply_chain" className="msg_reply">
            <MessageReply messageUUID={parentUUID} />
          </div>
        </div>
        <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleSnackClose} severity={snackSeverity}>
            {snackText}
          </Alert>
        </Snackbar>
      </div>
    )
  }
}

export default MessageReplyChain
