import { Redirect, Route } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import React, {Component, useEffect, useState} from "react";
import { Auth } from "@aws-amplify/auth";
import useUserStore from "../hook/useUserStore";
import CircularProgress from "@material-ui/core/CircularProgress";

const ProtectedRoute = ({ component: Component, path, exact }) => {
    const [loading, setLoading] = useState(false);
    const isAuth = useUserStore((state) => state.isAuth);
    const setIsAuth = useUserStore((state) => state.setIsAuth);

    useEffect(() => {
        setLoading(true);
        Auth.currentSession()
            .then(sess => {
                if(isAuth !== true) setIsAuth(true);
            }).catch(err => {
            setIsAuth(false);
        }).finally(() => setLoading(false));
    },[]);

    if(loading) return <CircularProgress className="loading-button-spinner"/>;

    if(!isAuth) {
        return (
            <Route
                path={path}
                exact={exact}
                render={props =>
                    <Component {...props} />
                }
            />
        );
    } else {
        return (
            <Route
                path={path}
                exact={exact}
                render={ props =>
                    <Redirect to="/" />
                }
            />
    )};
};

export default ProtectedRoute;