import { Redirect, Route } from 'react-router-dom'
// eslint-disable-next-line no-unused-vars
import React, { Component, useState, useEffect } from 'react'
import { Auth } from '@aws-amplify/auth'
import ApiService from '../ApiService'
import url from './routeURL'

const LockedRoute = ({ component: Component, path, exact }) => {
  const [userSignedIn, setUserSignedIn] = useState(true)
  const [customerId, setCustomerId] = useState('')
  const [region, setRegion] = useState(null)
  const [homeState, setHomeState] = useState(true)

  useEffect(() => {
    Auth.currentSession()
      .then(sess => {
        setCustomerId(sess.getIdToken().getJwtToken())
        setUserSignedIn(true)
      })
      .catch(err => {
        setUserSignedIn(false)
      })

    async function getPatient() {
      try {
        const getPatient = await ApiService.getPatientAfterSessRef()
        setRegion(getPatient.data.address.region)
      } catch (ex) {
        console.error(ex)
      }
    }
    getPatient()
  }, [customerId])

  useEffect(() => {
    if (region) {
        if (region === 'NY') {
            setHomeState(false)
            alert('We are not operational in New York Yet, please contact hello@tbd.health for more information')
        } else {
            setHomeState(true)
        }
    }
  }, [region])

  return <Route path={path} exact={exact} render={props => (userSignedIn && homeState ? <Component {...props} /> : <Redirect to={url.home} />)} />
}

export default LockedRoute;
