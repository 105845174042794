function getAlphaNumeric(inputString) {
  var alphanumericRegex = /[a-zA-Z0-9]/g

  var resultArray = inputString.match(alphanumericRegex)

  var resultString = resultArray ? resultArray.join('') : ''

  return resultString
}

export const POSITIVE_RESULT_STRINGS_LOWERCASE = ["detected", "positive", "reactive"];
export const NEGATIVE_RESULT_STRINGS_LOWERCASE = [
  "not-detected",
  "negative",
  "non-reactive",
  "notdetected",
  "nonreactive",
  "nottested",
  "not-tested",
  "notdetected",
  "noneseen"
];
export const IGNORE_TEST_NAMES_FOR_DISPLAY = ["Herpes Simplex Type 1 IgG QN", "Herpes Simplex Type 2 IgG QN"];

export const isPositiveResult = individualTest => {
  const resultString = getAlphaNumeric(individualTest?.result)?.toLowerCase();
  const summaryStatus = individualTest?.summaryStatus?.toUpperCase();
  return POSITIVE_RESULT_STRINGS_LOWERCASE.includes(resultString) || summaryStatus === "POSITIVE";
};

export const isNegativeResult = individualTest => {
  const resultString = getAlphaNumeric(individualTest?.result)?.toLowerCase();
  const summaryStatus = individualTest?.summaryStatus?.toUpperCase();
  return NEGATIVE_RESULT_STRINGS_LOWERCASE.includes(resultString) || summaryStatus === "NEGATIVE";
};

export const isOther = individualTest => {
  const summaryStatus = individualTest?.summaryStatus?.toUpperCase();
  return summaryStatus === "OTHER";
};

export const isInconclusive = individualTest => {
  const summaryStatus = individualTest?.summaryStatus?.toUpperCase();
  return summaryStatus === "INCONCLUSIVE" || (!isPositiveResult(individualTest) && !isNegativeResult(individualTest) && !isOther(individualTest));
};

export const isIgnoreTestForDisplay = individualTest => {
  const testName = getAlphaNumeric(individualTest?.testName)?.toLowerCase();
  return IGNORE_TEST_NAMES_FOR_DISPLAY.map(name => getAlphaNumeric(name)?.toLowerCase()).includes(testName);
};

export const TEST_CATEGORIES = {
  POSITIVE: "POSITIVE",
  NEGATIVE: "NEGATIVE",
  INCONCLUSIVE: "INCONCLUSIVE",
  OTHER: "OTHER",
  UNKNOWN: "UNKNOWN"
};