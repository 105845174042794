import React, { useEffect, useState } from 'react'
import ApiService from '../../ApiService'
import ReplyMessage from './ReplyMessage'
import { Button, Modal } from 'react-bootstrap'
import moment from 'moment'
import linkedText from '../../util/linked-text'
import { useHistory } from 'react-router-dom'
import './MessageTestDetail.css'
import { isIgnoreTestForDisplay, isInconclusive, isOther, isNegativeResult, isPositiveResult, TEST_CATEGORIES } from './resultUtils'

const MessageTestDetail = props => {
  const { messageUUID, message, sentMessage } = props
  const [replyMessageComponent, setReplyMessageComponent] = useState(null)
  const [show, setShow] = useState(true)
  const [clickedAckModal, setClickedAckModal] = useState(false)
  const [isShowReportBtn, setIsShowReportBtn] = useState(false)
  const data = props.message.testResult.results

  const handleClose = () => setShow(false)
  const handleCloseAck = () => setClickedAckModal(false)
  const handleShow = () => setShow(true)
  const history = useHistory()

  useEffect(() => {
    const { messageType, testResultHasAttachments } = props.message
    const isShowReportBtn = testResultHasAttachments === true && messageType?.toUpperCase() === 'TEST_RESULT'
    setIsShowReportBtn(isShowReportBtn)
  }, [props.message])

  function reply() {
    if (document.getElementById('ackcheckbox') && document.getElementById('ackcheckbox').checked) {
      setReplyMessageComponent(<ReplyMessage messageUUID={messageUUID} />)
    }
  }

  useEffect(() => {
    const filteredTests = data?.filter(test => !isIgnoreTestForDisplay(test))
    filteredTests.forEach(test => {
      if (isNegativeResult(test)) {
        document.getElementById('negRes').innerHTML += `<div class="display_results neg_display">${test.testName !== '' ? test.testName : 'Nil'}</div>` //appends html
        document.getElementById('negative-test').style.display = 'block' //displays test header
      } else if (isPositiveResult(test)) {
        document.getElementById('posRes').innerHTML += `<div class="display_results pos_display" >${test.testName !== '' ? test.testName : 'Nil'}</div>`
        document.getElementById('positive-test').style.display = 'block'
      } else if (isInconclusive(test)) {
        document.getElementById('notRes').innerHTML += `<div class="display_results not_display" >${test.testName !== '' ? test.testName : 'Nil'}</div>`
        document.getElementById('indeterminate-test').style.display = 'block'
      } else if (isOther(test)) {
        document.getElementById('otherRes').innerHTML += `<div class="display_results other_display" >${test.testName !== '' ? test.testName : 'Nil'}</div>`
        document.getElementById('other-test').style.display = 'block'
      }
    })
  }, [data])

  const ackResults = () => {
    document.getElementById('ackcheckbox').disabled = true
    ;(async function acknowledgeMessage() {
      try {
        // eslint-disable-next-line no-unused-vars
        const putMessage = await ApiService.acknowledgeMessage(messageUUID)
      } catch (ex) {
        console.error(ex)
      }
      setClickedAckModal(true)
    })()
  }

  useEffect(() => {
    if (document.getElementById('ackcheckbox') && message.readDateTime) {
      document.getElementById('ackcheckbox').checked = true
      document.getElementById('ackcheckbox').disabled = true
    } else if (document.getElementById('ackcheckbox')) {
      document.getElementById('ackcheckbox').checked = false
      document.getElementById('ackcheckbox').disabled = false
    }
  }, [message])

  useEffect(() => {
    if (message.readDateTime) {
      handleClose()
    } else {
      handleShow()
    }
  }, [message.readDateTime])

  if (!message) {
    return <div className="open_message">Fetching Message</div>
  } else {
    const formattedText = linkedText(message.testResult.externalNotes)
    return (
      <>
        <div className="open_message" id="open_message">
          {replyMessageComponent}
          <div className="message_header">
            <div className="from_header_el header-from">{message.from}</div>
            <div className="header-subject">{message.subject}</div>
            <div className="header-date">{moment(message.createdDateTime).format('ddd MMM D, YYYY LT')}</div>
            {sentMessage !== true ? (
              <button onClick={reply} id="msg_reply" className="replyButton">
                Reply <i className="fas fa-reply"></i>
              </button>
            ) : null}
          </div>
          {/* </div> */}
          <div className="message-margin">
            <div className="message_content">
              <div dangerouslySetInnerHTML={{ __html: formattedText }} className="message_text">
                {/* {message.testResult.externalNotes} */}
              </div>
              <div className="message_diagnosis">
                <div className="message_pos_container test_container_font">
                  <div
                    id="positive-test"
                    class="test_divider_font hidden-test"
                    style={{
                      backgroundColor: '#dc3545',
                      color: 'white',
                      textAlign: 'center',
                      width: '100%',
                      height: '30px',
                      borderRadius: '6px',
                      display: 'inline-flex',
                      fontSize: "18px",
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 9v2m0 4v.01"></path>
                      <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
                    </svg>
                    &nbsp;&nbsp;{TEST_CATEGORIES.POSITIVE}
                  </div>
                  <div className="positive_results results_container" id="posRes" />
                </div>

                <div className="message_neg_container test_container_font">
                  <div
                    id="negative-test"
                    class="test_divider_font hidden-test"
                    style={{
                      backgroundColor: '#28a745',
                      color: 'white',
                      textAlign: 'center',
                      width: '100%',
                      height: '30px',
                      borderRadius: '6px',
                      display: 'inline-flex',
                      fontSize: "18px",
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 11h10v2H7z"></path>
                      <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
                    </svg>
                    &nbsp;&nbsp;{TEST_CATEGORIES.NEGATIVE}
                  </div>
                  <div className="negative_results results_container" id="negRes" />
                </div>

                <div className="message_not_container test_container_font">
                  <div
                    id="indeterminate-test"
                    class="test_divider_font hidden-test"
                    style={{
                      backgroundColor: '#ffc107',
                      color: 'white',
                      textAlign: 'center',
                      width: '100%',
                      height: '30px',
                      borderRadius: '6px',
                      display: 'inline-flex',
                      fontSize: "18px",
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"></path>
                    </svg>
                    &nbsp;&nbsp;{TEST_CATEGORIES.INCONCLUSIVE}
                  </div>
                  <div className="negative_results results_container" id="notRes" />
                </div>

                <div className="message_not_container test_container_font">
                  <div
                    id="other-test"
                    class="test_divider_font hidden-test"
                    style={{
                      backgroundColor: '#007bff',
                      color: 'white',
                      textAlign: 'center',
                      width: '100%',
                      height: '30px',
                      borderRadius: '6px',
                      display: 'inline-flex',
                      fontSize: "18px",
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"></path>
                    </svg>
                    &nbsp;&nbsp;{TEST_CATEGORIES.OTHER}
                  </div>
                  <div className="other_results results_container" id="otherRes" />
                </div>

              </div>
            </div>
            <div className="test-checkbox-container" id="confirmBox">
              <input type="checkbox" className="test-checkbox" id="ackcheckbox" name="ackcheckbox" onClick={() => ackResults()} />
              <label id="test-checkbox-label" className="acknowledge-test-label" htmlFor="ackcheckbox">
                I've read this and acknowledge receipt
              </label>
            </div>
            {isShowReportBtn && (
              <div>
                <button onClick={() => history.push(`/history?kitId=${props.message.testResult.kitId}&action=access_report`)} className="Access-report">
                  {' '}
                  Download report
                </button>
              </div>
            )}
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          onClick={e => e.stopPropagation()}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Hey there!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please acknowledge that you have received your results.
            <br />- team tbd
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Understood
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={clickedAckModal}
          onHide={handleCloseAck}
          backdrop="static"
          keyboard={false}
          onClick={e => e.stopPropagation()}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            thank you for acknowledging receipt. Your response has been recorded.
            <br />- team tbd
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseAck}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default MessageTestDetail