import React, { useState } from 'react'
import { useZxing } from 'react-zxing'

const CONSTRAINTS = {
  video: { facingMode: 'environment' },
  audio: false,
}

const BarcodeScanner = ({ onScan, inputComponent }) => {
  const [scanning, setScanning] = useState(false)
  const [deviceId, setDeviceId] = useState(null)
  const [result, setResult] = useState('')
  const [cameraError, setCameraError] = useState(null)

  // Start scanning on a user gesture
  const handleStartScanning = () => {
    setScanning(true)
    navigator.mediaDevices
      .getUserMedia(CONSTRAINTS)
      .then(() => navigator.mediaDevices.enumerateDevices())
      .then(devices => {
        const cameras = devices.filter(device => device.kind === 'videoinput')
        if (cameras.length > 0) {
          const backCamera = cameras.find(device => device.label.toLowerCase().includes('back')) || cameras[0]
          setDeviceId(backCamera.deviceId)
        } else {
          setCameraError('Cannot access camera, please try again on a different device.')
        }
      })
      .catch(error => {
        console.error('Error checking camera availability:', error)
        setCameraError('Cannot access camera, please try again on a different device.')
      })
  }

  const onClose = () => {
    setScanning(false)
  }

  // Set up react-zxing hook to decode barcodes
  const { ref } = useZxing({
    paused: !deviceId || !scanning || result,
    deviceId,
    constraints: CONSTRAINTS,
    onError: error => {
      console.error('Error reading barcode:', error)
    },
    onResult: res => {
      if (res && res.text) {
        setResult(res.text)
        if (onScan) onScan(res.text)
        onClose()
      }
    },
    timeBetweenDecodingAttempts: 500,
  })

  // Reset scanning to allow new scans or to close the modal
  const handleReset = () => {
    setScanning(false)
    setResult('')
    setDeviceId(null)
    setCameraError(null)
  }

  return (
    <div>
      {/* Top row: Scan button and input component */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button
          type="button"
          onClick={handleStartScanning}
          className="registerKitFieldsNew"
          style={{
            width: '200px',
            background: '#ee8155',
            color: 'white',
            fontSize: '14px',
            lineHeight: '14px',
            border: 'none',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
            cursor: 'pointer',
            outline: 'none',
          }}
        >
          <img src="/img/barcode.png" alt="barcode" style={{ width: '28px', height: '28px' }} />
          Scan Barcode
        </button>
        <div
          style={{
            height: '50px',
            fontSize: '14px',
            textAlign: 'center',
            color: '#1F7F61',
            padding: '0 8px',
          }}
        >
          or
        </div>
        {inputComponent}
      </div>

      {/* Modal for camera view */}
      {scanning && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
          }}
        >
          {/* Close button */}
          <div
            onClick={handleReset}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              color: '#fff',
              fontSize: '48px',
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            &times;
          </div>
          {/* Instructional text */}
          {!cameraError && (
            <div
              style={{
                position: 'absolute',
                top: '12%',
                color: '#fff',
                fontSize: '18px',
                textAlign: 'center',
              }}
            >
              <p>Point the camera at your kit's barcode.</p>
              <p>Ensure the barcode is fully visible within the frame.</p>
            </div>
          )}
          {/* Video container */}
          <div
            style={{
              position: 'relative',
              width: '90%',
              maxWidth: '600px',
              height: '60%',
              borderRadius: '8px',
              overflow: 'hidden',
            }}
          >
            {cameraError ? (
              <div style={{ color: '#fff', fontSize: '18px', textAlign: 'center' }}>{cameraError}</div>
            ) : (
              <>
                <video ref={ref} autoPlay playsInline muted style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    pointerEvents: 'none',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '3px',
                      background: '#1F7F61',
                      animation: 'scan 2s linear infinite',
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <style jsx>{`
            @keyframes scan {
              0% {
                top: 0;
              }
              50% {
                top: 50%;
              }
              100% {
                top: 0;
              }
            }
          `}</style>
        </div>
      )}
    </div>
  )
}

export default BarcodeScanner
