import React, { useState, useEffect } from "react";
import ApiService from "../../ApiService";
import MessageTestDetail from "./MessageTestDetail";
import AppointmentRequest from "./AppointmentRequest";
import MessageText from "./MessageText";

const MESSAGE = "MESSAGE"
const DIRECT_MESSAGE = "DIRECT_MESSAGE"
const FREE_PREP_PROGRAM = "FREE_PREP_PROGRAM"

const MessageDetail = (props) => {
    const [messageUUID] = React.useState(props.messageUUID);
    const [message, setMessage] = useState(null);
    const [component, setComponent] = useState(null);
    const [sentMessage] = useState(props.sentMessage);

    useEffect(() => {
        async function getMessage() {
            try {
                const getMessage = await ApiService.getMessage(messageUUID);
                setMessage(getMessage.data);
            } catch(ex) {
                console.error(ex);
            }
        }
        getMessage();
    }, [messageUUID]);

    useEffect(() => {
        if (message) {
            if (message.messageType === "TEST_RESULT") {
                setComponent(<MessageTestDetail message={message} messageUUID={messageUUID} sentMessage={sentMessage}/>);
            } else if (message.messageType === "APPOINTMENT_REQUEST") {
                setComponent(<AppointmentRequest message={message} messageUUID={messageUUID}
                                                 sentMessage={sentMessage}/>);
            } else if (message.messageType === MESSAGE || message.messageType === DIRECT_MESSAGE || message.messageType === FREE_PREP_PROGRAM) {
                setComponent(<MessageText message={message} messageUUID={messageUUID} sentMessage={sentMessage}/>);
            }
        }
    }, [message, messageUUID, sentMessage]);
    
    if (!component) {
        return (
        <div class="open_message load_message">
            <p class="load_text">
                <i class="fas fa-spinner fa-pulse"></i>
                Loading Message
            </p>
        </div>
        )
    }
    else {
        return (
            <div class="msg_container">
                {component}
            </div>
        )
    }
}

export default MessageDetail;
